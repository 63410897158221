import { ErrorName } from '@dametis/core';

export const DEFAULT_ERROR = 'Se ha producido un error. Por favor, inténtelo de nuevo más tarde.';

const alerts: Record<ErrorName, string> = {
  USER_NOT_FOUND: 'Usuario inexistente',
  USER_BAD_SIGNIN: 'Contraseña no válida',
  // Common
  UNKNOWN: DEFAULT_ERROR,
  BAD_JSON: DEFAULT_ERROR,
  ROUTE_NOT_FOUND: DEFAULT_ERROR,
  VALIDATION: DEFAULT_ERROR,
  TOO_MANY_REQUESTS: 'Demasiadas solicitudes',
  NOT_IMPLEMENTED: DEFAULT_ERROR,
  // Session
  SESSION_ID_MISSING: DEFAULT_ERROR,
  SESSION_UNAUTHORIZED: DEFAULT_ERROR,
  SESSION_EXPIRED: 'La sesión ha expirado, por favor inicie sesión nuevamente',
  // PreSession
  PRESESSION_EXPIRED: 'La sesión ha expirado, por favor inicie sesión nuevamente',
  PRESESSION_ID_MISSING: DEFAULT_ERROR,
  PRESESSION_UNAUTHORIZED: DEFAULT_ERROR,
  // Token
  TOKEN_MISSING: DEFAULT_ERROR,
  TOKEN_UNAUTHORIZED: DEFAULT_ERROR,
  TOKEN_INVALID: DEFAULT_ERROR,
  TOKEN_ALREADY_EXISTS: DEFAULT_ERROR,

  CANNOT_SELF_IMPERSONATE: DEFAULT_ERROR,

  // 2FA
  USER_HAS_NO_TWOFA: DEFAULT_ERROR,
  TWOFA_INVALID_TYPE: DEFAULT_ERROR,
  TWOFA_ALREADY_ACTIVATED: 'Este método 2FA ya está activado con el mismo correo electrónico/teléfono',
  WRONG_ENROLL_TWOFA_CODE: 'Código incorrecto',
  EXPIRED_ENROLL_TWOFA_CODE: 'El código ha expirado, por favor intente nuevamente con uno nuevo',
  TWOFA_CHANGE_UNAUTHORIZED:
    'No es posible modificar el 2FA, el usuario debe tener al menos 2 métodos 2FA activos para poder modificar uno',
  USER_HAS_TWOFA: 'El usuario debe iniciar sesión a través de 2FA',
  USER_TWO_FA_NOT_ACTIVATED: 'Este método 2FA no está activado, por lo tanto no es posible desactivarlo',
  // CORS
  UNAUTHORIZED_CORS_ORIGIN: DEFAULT_ERROR,
  // Browser
  UNSUPPORTED_BROWSER: 'El navegador no es compatible',
  // User
  USER_INVALID_PASSWORD: 'Por favor elija una contraseña más segura. Intente con una combinación de letras, números y símbolos.',
  USER_INVALID_EMAIL: 'La dirección de correo electrónico del usuario parece ser inválida',
  USER_ALREADY_EXISTS: 'Este usuario ya existe',
  USER_ACCOUNT_NOT_ACTIVE: 'La cuenta de usuario no está activa',
  USER_ACCOUNT_NOT_INVITING: 'El usuario no está invitado',
  USER_BAD_PASSWORD: 'Credenciales incorrectas',
  USER_HAS_NO_PASSWORD: 'El usuario no tiene contraseña',
  USER_HAS_NO_PASSWORD_POLICY: DEFAULT_ERROR,
  USER_HAS_NOT_FINALIZED_INVITATION: 'El usuario no ha finalizado su invitación',
  USER_ALREADY_HAS_GROUP: 'El usuario ya tiene un grupo',
  USER_MISMATCH_GROUP_SITE: DEFAULT_ERROR,
  USER_CANNOT_DELETE_CURRENT_SESSION: DEFAULT_ERROR,
  INVALID_ID: DEFAULT_ERROR,
  USER_HAS_NO_GROUP: DEFAULT_ERROR,
  USER_MISMATCH_HEIR_GROUP: DEFAULT_ERROR,
  USER_STILL_HAS_ENTITIES: 'El usuario todavía tiene entidades asociadas',
  // Permission
  USER_ACCESS_DENIED: 'No tienes acceso al recurso solicitado',
  INVALID_ACL: DEFAULT_ERROR,
  // Group
  GROUP_NOT_FOUND: 'No se encontró el grupo solicitado',
  // Site
  SITE_NOT_FOUND: 'No se encontró el sitio solicitado',
  SITE_MISMTACH: DEFAULT_ERROR,
  SITE_HAS_NO_GROUP: DEFAULT_ERROR,
  // Site invitation
  SITE_INVITATION_NOT_FOUND: 'No se ha encontrado la invitación',
  SITE_INVITATION_BAD_EXPIRES_AT: 'El sitio no tiene función por defecto',
  SITE_NO_DEFAULT_ROLE: 'La invitación debe caducar en el plazo de un mes',
  SITE_INVITATION_EXPIRED: 'Invitación caducada',
  // Variable list
  VARIABLE_LIST_NOT_FOUND: 'No se encontró la lista de variables solicitada',
  // Box
  BOX_NOT_FOUND: 'No se encontró la box solicitada',
  BOX_HAS_NO_SITE: DEFAULT_ERROR,
  BOX_FAILED_TO_SYNCHRONIZE: 'La sincronización de la box ha fallado',

  // Ansible Box
  ANSIBLEBOX_NOT_FOUND: 'No se encontró la box de ansible solicitada',
  ANSIBLEBOX_LOCKED: 'La box de ansible solicitada ya está siendo configurada o la configuración anterior falló',
  ANSIBLEBOX_NOT_READY: 'La box de ansible no está lista para ser configurada, inténtelo nuevamente más tarde.',

  // Awx
  UNKNOWN_AWX_INVENTORY: DEFAULT_ERROR,
  AWX_HOST_INSERTION_FAILURE: DEFAULT_ERROR,
  AWX_HOST_DELETION_FAILURE: DEFAULT_ERROR,
  UNKNOWN_AWX_JOB: DEFAULT_ERROR,
  AWX_JOB_NOT_CREATED: DEFAULT_ERROR,

  // Device
  DEVICE_NOT_FOUND: 'No se encontró el equipo solicitado',
  DEVICE_INVALID_PROTOCOL: DEFAULT_ERROR,
  DEVICE_HAS_VARIABLES: 'El equipo tiene variables asociadas',
  DEVICE_AND_VARIABLE_INCOMPATIBLE: DEFAULT_ERROR,
  CANNOT_CHANGE_QUERYSTRATEGY: 'No es posible cambiar el modo de un equipo que ya ha sido creado',
  // Brand device
  BRAND_DEVICE_NOT_FOUND: 'No se encontró el equipo de marca solicitado',
  BRAND_DEVICE_HAS_DEVICES: 'El equipo de marca todavía tiene equipos asociados',
  // Variable
  VARIABLE_NOT_FOUND: 'No se encontró la variable solicitada',
  VARIABLE_UNKNOWN_KIND: 'Tipo de variable desconocido',
  VARIABLE_MEASUREMENT_NOT_FOUND: DEFAULT_ERROR,
  VARIABLE_BAD_REQUEST: DEFAULT_ERROR,
  VARIABLE_HAS_DEPENDANTS: 'La variable solicitada tiene dependencias y no puede ser eliminada',
  VARIABLE_HAS_CIRCUIT: 'La variable calculada forma un circuito',
  VARIABLE_MCO_OF_SITE: DEFAULT_ERROR,
  VARIABLE_MCO_INVALID_RANGE: DEFAULT_ERROR,
  VARIABLE_HAS_NO_REAL_NOR_GROUP_BY:
    'Error al crear, las variables calculadas deben tener al menos una variable adquirida o un subperíodo como dependencia',
  VARIABLE_IMPORT_INVALID_COLUMNS: 'Las columnas de la variable importada son inválidas',
  VARIABLE_MISSING_VERSION: 'Variable sin una versión',
  VARIABLE_IS_NOT_CALCULATED_KIND: 'La variable no es una variable calculada',
  VARIABLE_INTERMEDIATE_NOT_EDITABLE: 'No se puede eliminar una variable intermedia',
  // Variable set
  VARIABLE_SET_INVALID_ARGS: DEFAULT_ERROR,
  PLAYGROUND_SET_INVALID_ARGS: DEFAULT_ERROR,
  REPORT_SET_INVALID_ARGS: DEFAULT_ERROR,
  SYNOPTIC_SET_INVALID_ARGS: DEFAULT_ERROR,
  // Data
  DATA_NOT_FOUND: DEFAULT_ERROR,
  MISSING_MEASUREMENT: DEFAULT_ERROR,
  PARSING_VARCALC: DEFAULT_ERROR,
  UNKNOWN_NODE_TYPE: DEFAULT_ERROR,
  UNKNOWN_FUNCTION: 'Función desconocida',
  UNKNOWN_SYMBOL: 'Símbolo desconocido',
  GROUPBY_FORBIDDEN: 'Subperíodo no permitido',
  BAD_GROUPBY_CALCULATION: 'Subperíodo no permitido',
  MISSING_MOVING_AVERAGE_ARGS: DEFAULT_ERROR,
  MISSING_INDEX_ARGS: DEFAULT_ERROR,
  FILL_SETTING_UNKNOWN: 'El parámetro de relleno es desconocido',
  FORBIDDEN_FILL_SETTING: 'El parámetro de relleno solicitado está prohibido',
  FORBIDDEN_OPERATOR: 'El operador solicitado está prohibido',
  MISSING_TADA_ARGUMENT: DEFAULT_ERROR,
  UNDEFINED_TIME: DEFAULT_ERROR,
  TOO_MANY_VARS: 'Demasiadas variables solicitadas',
  INVALID_GROUPBY_PERIODS: DEFAULT_ERROR,
  INVALID_FLAG: 'La bandera no es válida',
  INVALID_OPERATOR_ARGS: DEFAULT_ERROR,
  INVALID_VARIABLE: DEFAULT_ERROR,
  UNIT_INVALID: DEFAULT_ERROR,
  UNIT_INVALID_CONVERSION: DEFAULT_ERROR,
  // Alarm
  ALARM_NOT_FOUND: 'No se encontró la alarma solicitada',
  ALARM_MCO_NOT_WRITABLE: 'Esta alarma no es editable',
  CONDITION_NOT_FOUND: DEFAULT_ERROR,
  INCIDENT_NOT_FOUND: 'No se encontró el incidente solicitado',
  INCIDENT_ALREADY_ACKED: 'El incidente ya ha sido tomado en cuenta',
  INCIDENT_INVALID: DEFAULT_ERROR,
  INVALID_EXTENDED_CONDITION: DEFAULT_ERROR,
  EVENT_NOT_FOUND: 'No se encontró el evento solicitado',
  EVENT_INVALID: DEFAULT_ERROR,
  // Synoptic
  SYNOPTIC_NOT_FOUND: 'No se encontró el sinóptico solicitado',
  // Synoptic widget
  SYNOPTIC_WIDGET_NOT_FOUND: 'No se encontró el widget de sinóptico solicitado',
  // Playground
  PLAYGROUND_NOT_FOUND: 'Playground no encontrado',
  PLAYGROUND_NOT_WRITABLE: 'Este playground no es modificable',
  // Report
  REPORT_NOT_FOUND: 'Informe no encontrado',
  REPORT_NOT_WRITABLE: 'Informe no modificable',
  REPORT_VARIABLE_NOT_FOUND: 'Variable en el informe no encontrada',
  REPORT_NOT_IN_GROUP_SCOPE: 'El informe no pertenece a este grupo',
  REPORT_NOT_IN_SITE_SCOPE: 'El informe no pertenece a este sitio',
  // Data
  INVALID_MATH_EXPRESSION: 'Expresión matemática no válida',
  MATH_EVALUATION: 'Error al evaluar la expresión matemática',
  INCOMPLETE_DATA: 'Datos proporcionados incompletos',
  MATH_MISSING_DATA: 'Datos faltantes en la variable explicada o en las variables explicativas',
  INVALID_GROUP_BY: DEFAULT_ERROR,
  NO_VARS_PROVIDED: DEFAULT_ERROR,
  DIFFERENT_DATA_SIZE: DEFAULT_ERROR,
  INVALID_PERIOD: DEFAULT_ERROR,
  PERIOD_REQUIRED: DEFAULT_ERROR,
  AN_ERROR_OCCURRED: DEFAULT_ERROR,
  UNKNOWN_OPERATOR: 'Operador desconocido',
  // Math Data
  UNKNOWN_MATH_DATA_OPERATOR: DEFAULT_ERROR,
  REQUIRED_GROUPBY: DEFAULT_ERROR,
  GROUPBY_MUST_BE_EQUALS: DEFAULT_ERROR,
  MISSING_RETRO_CALCULATED_SINCE: DEFAULT_ERROR,
  MISSING_GLOBAL_GROUPBY_CALC: DEFAULT_ERROR,
  // Clickouse
  INVALID_QUERY: DEFAULT_ERROR,
  CREDENTIALS_NOT_FOUND: DEFAULT_ERROR,
  NO_POINTS_RETURNED: DEFAULT_ERROR,
  MAX_BUCKET_COUNT_EXCEEDED: 'Demasiados datos para procesar, por favor reduzca el intervalo de tiempo y/o aumente el subperíodo',
  MAX_PERIODS_COUNT_EXCEEDED: 'Se ha excedido el número máximo de períodos temporales',
  MOVING_AVERAGE_EXPECTS_ARGS: DEFAULT_ERROR,
  FORBIDDEN_HISTORY_WITH_GROUP_BY: DEFAULT_ERROR,
  CLICKHOUSE_UNAVAILABLE: DEFAULT_ERROR,
  CLICKHOUSE_NETWORK_ERROR: DEFAULT_ERROR,
  // Rabbit
  RABBIT_UNAVAILABLE: DEFAULT_ERROR,
  // Minio
  MINIO_UNAVAILABLE: DEFAULT_ERROR,
  BAD_PREVIEW_TYPE: 'Tipo de vista previa no válido',
  CORRUPTED_PREVIEW: 'Imagen corrupta',
  NO_PREVIEW_DEFINED: 'Vista previa no definida',
  HEAVY_PREVIEW: 'Imagen demasiado pesada',
  // Colossus
  COLOSSUS_UNAVAILABLE: DEFAULT_ERROR,
  // Email
  EMAIL_FAILURE: DEFAULT_ERROR,
  EMAIL_MESSAGE_NOT_FOUND: DEFAULT_ERROR,
  // Streaming
  STREAMING_INVALID_BODY: DEFAULT_ERROR,
  STREAMING_HANDLER_ALREAD_USED: DEFAULT_ERROR,
  STREAMING_AUTHENTICATION_FAILURE: DEFAULT_ERROR,
  STREAMING_REQUEST_LIMIT_REACHED: DEFAULT_ERROR,
  STREAMING_HANDLER_NOT_FOUND: DEFAULT_ERROR,
  // Project
  PROJECT_NOT_FOUND: 'Proyecto no encontrado',
  PROJECT_INVALID: 'Proyecto no válido',
  PROJECT_TEMPLATE_NOT_FOUND: 'Template de Proyecto no encontrado',
  PROJECT_TEMPLATE_HAS_PROJECTS: 'Template de Proyecto tiene Proyectos asociados',
  ASSET_NOT_FOUND: 'Adjunto no encontrado',
  TASK_NOT_FOUND: 'Tarea no encontrada',
  IMAGE_NOT_FOUND: 'Imagen no encontrada',
  // Calendar
  CALENDAR_NOT_FOUND: 'Calendario no encontrado',
  // Role
  ROLE_NOT_FOUND: DEFAULT_ERROR,
  ROLE_FORBIDDEN: DEFAULT_ERROR,
  // Batch
  BATCH_NOT_FOUND: 'Batch no encontrado',
  BATCH_HEIGHT_TOO_HIGH: 'Batch demasiado grande',
  // Comment
  COMMENT_NOT_FOUND: 'Comentario no encontrado',
  COMMENT_INVALID: 'Comentario inválido',
  // Users List
  USER_LIST_NOT_FOUND: 'Lista de usuarios no encontrada',
  // API
  API_UNAVAILABLE: DEFAULT_ERROR,
  // BULL
  BULL_UNAVAILABLE: DEFAULT_ERROR,
  // MONGO
  MONGO_UNAVAILABLE: DEFAULT_ERROR,
  // Model
  MODEL_NOT_FOUND: 'El modelo solicitado no ha sido encontrado',
  // Activity
  ACTIVITY_NOT_FOUND: 'La actividad solicitada no ha sido encontrada',
  ACTIVITY_NOT_RESTORABLE: 'La actividad solicitada no puede ser restaurada',
  // Tag
  TAG_NOT_FOUND: 'La tag solicitada no ha sido encontrada',
  TAG_ALREADY_EXISTS: 'Una tag con este nombre ya existe',
  TAG_EDITOR_FAILURE: DEFAULT_ERROR,
  // Macro
  MACRO_NOT_FOUND: 'La macro solicitada no ha sido encontrada',
  MACRO_JOB_NOT_FOUND: 'El trabajo de macro solicitado no ha sido encontrado',
  MACRO_JOB_NOT_CREATED: 'El trabajo de macro no pudo ser creado',
  // Macro File
  MACRO_FILE_NOT_FOUND: 'El archivo de macro solicitado no ha sido encontrado',
  // Macro Library
  MACROLIB_NOT_FOUND: 'La biblioteca de macro solicitada no ha sido encontrada',
  // Streaming
  SOCKET_IO: DEFAULT_ERROR,
  // Facturation
  CONTRACT_NOT_FOUND: 'El contrato solicitado no ha sido encontrado',
  CONTRACT_HAS_BILLS: 'El contrato solicitado tiene facturas asociadas y no puede ser eliminado',
  BILL_NOT_FOUND: 'La factura solicitada no ha sido encontrada',
  BILL_INVALID_DATES: 'Las fechas de la factura no están dentro de las fechas del contrato',
  // AlarmConnector
  ALARM_CONNECTOR_NOT_FOUND: 'El conector de alarma solicitado no ha sido encontrado',
  ALARM_CONNECTOR_HAS_DEPENDANTS: 'El conector de alarma solicitado tiene dependencias y no puede ser eliminado',
  // Block
  BLOCK_NOT_FOUND: 'El bloque solicitado no ha sido encontrado',
  BLOCK_METRIC_TEMPLATING_FAILURE: DEFAULT_ERROR,
  METRIC_NOT_FOUND: DEFAULT_ERROR,
  METRIC_NOT_VIRTUAL: DEFAULT_ERROR,
  // Block Type
  BLOCK_TYPE_NOT_FOUND: 'El tipo de bloque solicitado no ha sido encontrado',
  BLOCK_TYPE_DELETE_USED_PARAMETER: 'El parámetro del tipo de bloque solicitado está en uso y no puede ser eliminado',
  BLOCK_TYPE_DELETE_USED_METRIC: 'La métrica del tipo de bloque solicitado está en uso y no puede ser eliminada',
  BLOCK_TYPE_PARAMETERS_METRICS_NON_UNIQUE: 'Los parámetros y métricas del tipo de bloque deben ser únicos',
  // Stantard block
  STANDARD_BLOCK_NOT_FOUND: 'El bloque estándar solicitado no ha sido encontrado',
  TOO_MANY_POINTS: DEFAULT_ERROR,
  UNKNOWN_VAR_CALC: DEFAULT_ERROR,
  UNSUPPORTED_CALCULATION: DEFAULT_ERROR,
  MISSING_VERSION: DEFAULT_ERROR,
  MODEL_KEY_NOT_FOUND: DEFAULT_ERROR,
  ALARM_SET_INVALID_ARGS: DEFAULT_ERROR,
  // Consul
  CONSUL_UPDATE_CONFIG: DEFAULT_ERROR,
  // Folder
  FOLDER_NOT_FOUND: 'La carpeta no ha sido encontrada',
  FOLDER_ROOT_ALREADY_EXISTS: 'Carpeta raíz ya existente',
  FOLDER_INVALID_OPERATION: 'Operación de carpeta inválida',
  // Authentication
  AUTHENTICATION_ALREADY_EXISTS: 'Este nombre de autenticación ya está en uso',
  AUTHENTICATION_NOT_FOUND: 'La autenticación solicitada no ha sido encontrada',
  AUTHENTICATION_USED_AS_FALLBACK: 'No puedes vaciar una autenticación que está siendo utilizada como respaldo',
  AUTHENTICATION_USES_ITSELF_AS_FALLBACK: 'Una autenticación no puede respaldarse a sí misma',
  CANNOT_DELETE_DEFAULT_PASSWORD: 'No puedes vaciar la autenticación predeterminada',
  // Twilio
  LOCALE_NOT_FOUND: DEFAULT_ERROR,
  TWILIO_MESSAGE_NOT_FOUND: DEFAULT_ERROR,
  TWILIO_MESSAGE_NOT_SEND: DEFAULT_ERROR,
  INVALID_PHONE_NUMBER: 'Número de teléfono incorrecto',
  // StyleConfiguration
  STYLE_CONFIGURATION_NOT_FOUND: 'La Configuración de Estilo solicitada no ha sido encontrada.',
  // Alias
  ALIAS_NOT_FOUND: 'El Alias solicitado no ha sido encontrado.',
  // DataOperation
  DATAOPERATION_NOT_FOUND: 'La operación de datos no ha sido encontrada.',
  CANNOT_RETRY_DATA_OPERATION: 'No se puede reintentar la operación',
  CANNOT_REIMPORT_DATA_OPERATION: 'No se puede reintentar la operación de Importación',
  CANNOT_REVERT_DATA_OPERATION: 'No se puede restaurar la operación',
  // EditVariablesData
  EDIT_VARIABLES_DATA_BAD_REQUEST: DEFAULT_ERROR,
  // FileImport
  FILEIMPORT_NOT_FOUND: 'La importación de archivo no ha sido encontrada.',
  FILE_ALREADY_EXISTS: 'El archivo ya existe',
  FILE_NOT_CREATED: 'El archivo no pudo ser creado',
  FILE_MAX_SIZE_EXCEEDED: 'El archivo supera el tamaño máximo autorizado',
  FILE_DOES_NOT_EXISTS: 'El archivo no existe',
  FILE_PARSE_ERROR: 'No se puede analizar el archivo',
  // Webauthn
  WEBAUTHN_ERROR: DEFAULT_ERROR,
  WEBAUTHN_EXPIRED_CHALLENGE: 'El desafío expiró. Inténtalo de nuevo',
  WEBAUTHN_WRONG_PASSKEY: 'El servidor no conoce la clave proporcionada.',
  WEBAUTHN_INVALID_SIGNATURE: '',
  WEBAUTHN_PASSKEY_REGISTRATION: 'El servidor no pudo registrar la clave proporcionada',

  BLOCK_TYPE_HAS_STANDARD_BLOCKS: DEFAULT_ERROR,
  STANDARD_BLOCK_HAS_BLOCKS: DEFAULT_ERROR,

  // Opco
  OPCO_NOT_FOUND: 'Mezcla de vapor no encontrada',
  OPCO_UNAVAILABLE_ON_SITE: 'Mezcla de vapor no disponible en tu sitio',
  OPCO_INPUT_FILE_ALREADY_UPLOADED: 'El archivo de entrada ya se ha cargado',
  OPCO_INPUT_FILE_WRONG_MIMETYPE: 'La extensión del archivo de entrada no es válida',
  OPCO_DELETE_RUNNING_OPCO: 'No se puede eliminar una mezcla de vapor en ejecución',
  OPCO_FILE_NOT_FOUND: 'El archivo no se encuentra',
  OPCO_DOWNLOAD_TEMPLATE_ERROR: 'Ocurrió un error al descargar la plantilla',
  CREATE_REPORT_OPCO_STATUS_NOT_SUCCESS: 'La creación del informe falló',
  OPCO_REPORT_TEMPLATE_NOT_FOUND: 'No se encontró la plantilla del informe',
  OPCO_REPORT_VARIABLE_NOT_FOUND: 'Falta una variable',

  TADA_TEMPLATING_VARIABLE_NOT_FOUND: DEFAULT_ERROR,
};

export default {
  pageTitle: {
    403: 'Acceso denegado - Dametis',
    404: 'Página no encontrada - Dametis',
  },
  title: {
    crash: DEFAULT_ERROR,
    notFound: 'Página no encontrada',
    accessDenied: 'Acceso denegado',
    requestAccessTo: 'Solicitar acceso a',
  },
  text: {
    crash1: 'Por favor verifique que su navegador esté actualizado. Si el error persiste, puede',
    crash2: 'contactar nuestro soporte',
    notFound: 'La página que busca no existe',
    accessDenied: 'No tienes acceso a',
    askAccess: 'Puedes solicitar acceso por correo a',
    notFoundOrAccessDenied: 'La página que busca no existe, o no tiene acceso a ella',
    requestAccessTo: 'Vas a solicitar acceso por correo a',
  },
  button: {
    back: 'Volver a la página de inicio',
    learnMore: 'Saber más',
    requestAccess: 'Solicitar acceso',
    cancel: 'Cancelar',
    submit: 'Enviar',
  },
  label: {
    comment: 'Comentario',
  },
  toast: {
    accessRequested: 'Acceso solicitado',
  },
  alerts,
};
