import { Close, Feed, FeedOutlined, Router, RouterOutlined, VpnKey, VpnKeyOutlined } from '@mui/icons-material';
import { FC, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { IsFileDevice, IsFileDeviceSftpInternalSource, ShortDeviceInfo, UUID } from '@dametis/core';

import IconTab from 'components/UI/IconTabs/IconTab';
import IconTabs from 'components/UI/IconTabs/IconTabs';
import { RightPanel, RightPanelHeader, RightPanelHeaderButton, RightPanelHeaderText } from 'components/UI/RightPanel';
import RightPanelDrawer from 'components/UI/RightPanel/RightPanelDrawer';
import { usePermission } from 'hooks/usePermission';
import { useDevices } from 'store/api/devices';

import CredentialsPanel from './CredentialsPanel/CredentialsPanel';
import InformationsPanel from './InformationsPanel';
import RealVariablesPanel from './RealVariablesPanel/RealVariablesPanel';

const devicesEmptyArray: ShortDeviceInfo[] = [];

export enum PanelTab {
  INFORMATIONS = 'informations',
  REAL_VARIABLES = 'realVariables',
  CREDENTIALS = 'credentials',
}

const DeviceRightPanel: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('devices');

  const { deviceId } = useParams<{ deviceId?: UUID }>();

  const canEditConfiguration = usePermission('canEditConfiguration');

  const { data: devices = devicesEmptyArray } = useDevices();

  const [selectedPanelTab, setSelectedPanelTab] = useState<PanelTab>(PanelTab.INFORMATIONS);

  const device = useMemo(() => devices.find(storeDevice => storeDevice.uuid === deviceId), [devices, deviceId]);

  const hasCredentials = useMemo(() => device && IsFileDevice(device) && IsFileDeviceSftpInternalSource(device.file.source), [device]);

  const handleClosePanel = useCallback(() => {
    navigate(`/configuration/devices`, { state: { noScrollTop: true } });
  }, [navigate]);

  const handleChangePanelTab = useCallback((event: SyntheticEvent, newPanelTab: PanelTab) => {
    setSelectedPanelTab(newPanelTab);
  }, []);

  useEffect(() => {
    if (deviceId) {
      if (selectedPanelTab === PanelTab.CREDENTIALS && !(canEditConfiguration && hasCredentials)) {
        setSelectedPanelTab(PanelTab.INFORMATIONS);
      }
    }
  }, [canEditConfiguration, deviceId, hasCredentials, selectedPanelTab]);

  return (
    <RightPanelDrawer>
      {device && (
        <RightPanel>
          <RightPanelHeader>
            <RightPanelHeaderButton icon={Close} onClick={handleClosePanel} />
            <RightPanelHeaderText
              // subtitle={<EntityPanelSubtitle entity={device} />}
              title={device.name}
            />
          </RightPanelHeader>
          <IconTabs centered value={selectedPanelTab} variant="fullWidth" onChange={handleChangePanelTab}>
            <IconTab
              activeIcon={<Feed />}
              icon={<FeedOutlined />}
              isActive={selectedPanelTab === PanelTab.INFORMATIONS}
              label={t(`panelTab.${PanelTab.INFORMATIONS}`)}
              value={PanelTab.INFORMATIONS}
            />
            <IconTab
              activeIcon={<Router />}
              icon={<RouterOutlined />}
              isActive={selectedPanelTab === PanelTab.REAL_VARIABLES}
              label={t(`panelTab.${PanelTab.REAL_VARIABLES}`)}
              value={PanelTab.REAL_VARIABLES}
            />
            {canEditConfiguration && hasCredentials && (
              <IconTab
                activeIcon={<VpnKey />}
                icon={<VpnKeyOutlined />}
                isActive={selectedPanelTab === PanelTab.CREDENTIALS}
                label={t(`panelTab.${PanelTab.CREDENTIALS}`)}
                value={PanelTab.CREDENTIALS}
              />
            )}
          </IconTabs>
          {selectedPanelTab === PanelTab.INFORMATIONS && <InformationsPanel device={device} />}
          {selectedPanelTab === PanelTab.REAL_VARIABLES && <RealVariablesPanel device={device} />}
          {selectedPanelTab === PanelTab.CREDENTIALS && canEditConfiguration && hasCredentials && <CredentialsPanel device={device} />}
        </RightPanel>
      )}
    </RightPanelDrawer>
  );
};

export default DeviceRightPanel;
